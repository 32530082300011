import React, { useEffect, useRef, useState } from "react";
import logo from "../assests/images/header/genaiLogo.png";
import Appcss from "../changepassword/Changepassword.module.css";
import frmcss from "../register/register.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Charging from "../Charging";
import {  postActivateUser  } from "../commonApi";
import { Toast } from "primereact/toast";

const ActivateAccount = () => {
    const [open, setOpen] = useState(false);
    const toast = useRef(null);
    const navigate = useNavigate();
    const {organization}=useParams()
  
    // Get the user_id from the URL
    const { user_id } = useParams();
   
  
    // Function to show error messages
    const showError = (message) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
        life: 3000,
      });
    };
  
    // Function to show success messages
    const showSuccess = (message) => {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: message,
        life: 3000,
      });
    };
  
    // Function to handle activation button click
    const activateBtn = async () => {
      try {
        setOpen(true); // Show loading spinner
        const response = await postActivateUser({ user_id ,status:1});
  
        if (response.success) {
          showSuccess("Account activated successfully!");
          navigate(`/activated/${organization}`); // Navigate to login page on success
        } else {
          showError(response.message || "Activation failed");
        }
      } catch (error) {
        showError("An error occurred while activating the account.");
      } finally {
        setOpen(false); // Hide loading spinner
      }
    };

    return (
        <div>
            <Toast ref={toast} />
            <Charging open={open} />
            <div className={Appcss.bdy}>
                <header>
                    <img className={Appcss.image} src={logo} alt="logo" />
                </header>
                <div className={Appcss.box}>
                    <h2 className={Appcss.reset}>Activate Your Account</h2>
                    <button onClick={() => activateBtn()} className={Appcss.activateBtn} >Activate</button>
                    <p className={frmcss.member}>
                        <Link to={`/TestingAide/${organization}`} className={frmcss.register_now} >
                            Go back to Login
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ActivateAccount;
