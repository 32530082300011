import React, { useState, useEffect } from "react";
import styles from "../businessmanagement/pop.module.css"; // Adjust the import path according to your project structure
import { Select, MenuItem } from "@mui/material";
import { BusinessunitUsers } from "../../commonApi";

const BusinessunitPopup = ({ show, onClose, onConfirm, msgs }) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.fnt1new}>
            This Buiness Unit already has some users. Assign them to other
            Business Unit
          </div>
          {/* <div className={styles.fnt}>
            Do you want to Delete entire business unit?
          </div> */}
          <div className={styles.buttonContainer}>
            {/* <button className={styles.button_s} onClick={onConfirm}>
              Yes
            </button> */}
            <button className={styles.button_c} onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessunitPopup;
