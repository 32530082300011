import React, { Suspense, lazy, useState } from "react";
import Loading from "../components/loading";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Layout from "../Layout";

import SameLayout from "../sameLayout";
import TestProjects from "../AutomationTest/TestProjects";

import { useSelector } from "react-redux";
import Charging from "../Charging";
import { element } from "prop-types";

import TestStrategy from "../AutomationTest/TestStrategy";
import Orglogin from "../login/Orglogin";
import Orgregister from "../register/Orgregister";
import RegisterSuccess from "../register/RegisterSuccess";
import AccountActivatedOrg from "../register/AccountactivatedOrg";
import UserManagement from "../management/usermanagement/index";
import UsermanagementIdp from "../UsermanagementIdp/UsermanagementIdp";
import ResourceGroup from "../ResourceGroup/ResourceGroup";
import UserGroups from "../UsermanagementIdp/UserGroups";
import RoleManagement from "../management/rolemanagement/rolemanagement";
import BusinessManagement from "../management/businessmanagement/businessmangement";
import Usercreate from "../management/usercreate";
import Rolecreate from "../management/rolecreate";
import Profile from "../management/profile";

import ActivateAccountOrg from "../register/ActivateAccountOrg";

const RoutingGenai = () => {
  const loading = useSelector((store) => store.loadingErrorStore.loading);
  console.log(`loading--${loading}`);

  return (
    <>
      <Charging open={loading} />
      <Suspense fallback={<Loading />}>
        <Router>
          <Routing />
        </Router>
      </Suspense>
    </>
  );
};

const Routing = () => {
  const [c1, setC1] = useState(0);

  function counting(data) {
    setC1(data);
  }

  let MainRoutes = {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/TestingAide/:organization", element: <Orglogin /> },
      { path: "/register/:organization", element: <Orgregister /> },
      { path: "/registerSuccess", element: <RegisterSuccess /> },
      { path: "/activated/:organization", element: <AccountActivatedOrg /> },
      { path: "/:organization/login/activateuser/llmops/:user_id", element: <ActivateAccountOrg /> },
    ],
  };

  let sameRoutes = {
    path: "/",
    element: <SameLayout counting={counting} />,
    children: [
      {
        path: "/projectdetails",
        element: <TestStrategy />,
      },
      { path: "/projects/:organization", element: <TestProjects /> },
      { path: "/userManagement/:organization", element: <UserManagement /> },
      {
        path: `/userManagementIdp/:organization`,
        element: <UsermanagementIdp />,
      },
      { path: `/ResourceGroup/:organization`, element: <ResourceGroup /> },
      { path: "/UserGroups/:organization", element: <UserGroups /> },
      { path: "/roleManagement/:organization", element: <RoleManagement /> },
      {
        path: "/businessmanagement/:organization",
        element: <BusinessManagement />,
      },

      { path: "/userManagement/usercreate/", element: <Usercreate /> },
      {
        path: "/roleManagement/rolecreate/:organization",
        element: <Rolecreate />,
      },

      { path: "/profile", element: <Profile /> },

      // { path: "/appstore/TestAutomation", element: <TestAutomation /> },
    ],
  };

  return useRoutes([MainRoutes, sameRoutes]);
};

export default RoutingGenai;
