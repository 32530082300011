import React, { useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./login.module.css";
import log from "../assests/images/header/genaiLogo.png";
import robo from "../assests/images/auth/Robot.png";
import cx from "classnames";
import { loginAPI, loginAPIOrg } from "../commonApi";
import Charging from "../Charging";
import { Toast } from "primereact/toast";
import Cookies from "universal-cookie";
import Robot from "../Charging/Robot";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const [eye, setEye] = useState(true);
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [checkbox, setCheckbox] = useState(false);

//   useEffect(() => {
//     if (organization) {
//       localStorage.setItem("organization", organization); // Store in localStorage
//     }
//   }, [organization]);
const {organization}=useParams();

  console.log("checkbox----", checkbox);

  const updateEmail = (event) => {
    setEmail(event.target.value);
  };

  const updatePassword = (event) => {
    setPassword(event.target.value);
  };

  async function submitForm(e) {
    try {
      e.preventDefault();
      setOpen(true);
      let body = { email: email, password: password };
      const res = await loginAPIOrg(body,organization);
      console.log("res---", res);
      setOpen(false);
      if (res.success) {
        localStorage.setItem("genAi", JSON.stringify(res.token));
        // localStorage.setItem("organization", res.organization); 
        cookies.set("genAi", JSON.stringify(res.token), {
          maxAge: 60 * 60 * 3,
        });
        // navigate(`/projects/${organization}`);
        navigate(`/projects/${organization}`);


      } else {
        showError(res.message);
      }
    } catch (e) {
      setOpen(false);
      console.log("There is an Error---", e);
      showError("Enter vaild email and password");
    }
  }

  const showError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 4000,
    });
  };
  const handleRegisterClick = () => {
    navigate(`/register/${organization}`);
  };

  return (
    <>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={cx(styles.container, "container")}>
        <div className={cx(styles.row1, "col-lg-6")}>
          <h2 className={styles.h1}>Bring your data back to life </h2>
          <Robot />
        </div>
        <div className={cx(styles.row1, "col-lg-6")}>
          <img className={styles.log} src={log} alt="logo" />
          <h1 className={styles.h1}>Login </h1>
          <h3 className={styles.h3}>Welcome to GenAI</h3>

          <form onSubmit={submitForm} className={styles.formSize}>
            <div>
              {/* <div>
                <input type="checkbox" name="mani" checked={checkbox} onChange={() => setCheckbox(!checkbox)} />
                <input type="checkbox" name="mani" checked={checkbox} onChange={() => setCheckbox(!checkbox)} />
                <input type="checkbox" name="mani" checked={checkbox} onChange={() => setCheckbox(!checkbox)} />
              </div> */}
              <input
                placeholder="Email"
                name="email"
                type="email"
                onChange={updateEmail}
                required
                autoComplete="off"
                className={styles.login_inp}
              />
            </div>
            <div className="posRel">
              <input
                placeholder="Password"
                name="password"
                type={eye ? "password" : "text"}
                onChange={updatePassword}
                required
                autoComplete="off"
                className={styles.login_inp}
              />
              {eye ? (
                <i
                  onClick={() => setEye(!eye)}
                  className={cx("fa-solid fa-eye-slash", styles.eye)}
                ></i>
              ) : (
                <i
                  onClick={() => setEye(!eye)}
                  className={cx("fa-solid fa-eye", styles.eye)}
                ></i>
              )}
            </div>
            <div>
              <div className="justifyEnd">
                <Link to="/forgotpassword" className={styles.forgot}>
                  Forgot password?
                </Link>
              </div>

              <div className={styles.sak}>
                <button className={styles.button}>Login</button>

                <h6 className={styles.h6}>
                  Not a member yet?
                  <span onClick={handleRegisterClick} className={styles.tar}>
                    Register now
                  </span>
                </h6>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
