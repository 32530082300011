import React from "react";
import logo from "../assests/images/header/genaiLogo.png";
import Appcss from "../changepassword/Changepassword.module.css";
import frmcss from "../register/register.module.css";
import { Link, useParams } from "react-router-dom";
import { frontend_url } from "../config";

const AccountActivatedOrg = () => {

    const {organization}=useParams();

    const { userId } = useParams();
    console.log('userId-------->', userId);

    return (
        <div>
            <div className={Appcss.bdy}>
                <header>
                    <img className={Appcss.image} src={logo} alt="logo" />
                </header>
                <div className={Appcss.box}>
                    <h2 className={Appcss.reset}>Account Activated</h2>
                    {/* <button className={Appcss.activateBtn} >Activate</button> */}
                    <p className={frmcss.member}>
                        {/* <a href={frontend_url} className={frmcss.register_now} >
                            Go to Login
                        </a> */}
                        <Link to={`/TestingAide/${organization}`}className={frmcss.register_now} >
                            Go back to Login
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AccountActivatedOrg;
