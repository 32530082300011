import React, { useEffect, useState } from "react";
import styles from "../businessmanagement/ReassignBu.module.css";
import { Get_business_units, assignUsers } from "../../commonApi";
import { useParams } from "react-router-dom";

const ReassignBu = ({ show, onClose }) => {
  const { organization } = useParams();

  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedUnitName, setSelectedUnitName] = useState("");
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (organization) {
      fetchBusinessUnits();
    }
  }, [organization]);

  const fetchBusinessUnits = async () => {
    const businessData = await Get_business_units(organization);
    if (businessData.success) {
      setBusinessUnits(businessData.data); // Store the fetched business units in state
    } else {
      console.error("Failed to fetch business units");
    }
  };

  const handleUserSelection = (event) => {
    const selectedUnitName = event.target.value;
    setSelectedUnitName(selectedUnitName);

    const unitData = businessUnits.find(
      (unit) => Object.keys(unit)[0] === selectedUnitName
    );
    setSelectedBusinessUnit(unitData); // Store selected business unit details
  };

  const handleConfirm = async () => {
    if (!selectedBusinessUnit) {
      alert("Please select a business unit.");
      return;
    }

    const [unitName, unitUsers] = Object.entries(selectedBusinessUnit)[0];

    const data = {
      bu_id: parseInt(unitUsers[0].bu_id, 10), // Assuming all users share the same business unit ID
      bu_name: unitName,
      users: unitUsers.map((user) => ({ user_id: user.user_id })), // Extract user IDs for users in this unit
    };

    try {
      setLoading(true);
      const response = await assignUsers(data, organization);

      if (response.success) {
        fetchBusinessUnits();

        console.log("Assignment Successful:", response.data);
        alert("Business unit assigned successfully!");
        onClose(); // Close the modal after success
      } else {
        console.error("Assignment failed:", response.message);
        alert("Failed to assign business unit.");
      }
    } catch (error) {
      console.error("Error during assignment:", error);
      alert("An error occurred during assignment.");
    } finally {
      setLoading(false);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.fnt}>Assign New Business Unit</div>
          <select
            className={styles.dDown}
            value={selectedUnitName}
            onChange={handleUserSelection}
            required
          >
            <option value="" disabled>
              Select a Business Unit
            </option>
            {businessUnits.map((unit, idx) => {
              const [unitName] = Object.keys(unit);
              return (
                <option key={idx} value={unitName}>
                  {unitName}
                </option>
              );
            })}
          </select>
          <div className={styles.align}>
            <button
              className={styles.button_s}
              onClick={handleConfirm}
              disabled={loading}
            >
              {loading ? "Assigning..." : "Confirm"}
            </button>
            <button className={styles.button_c} onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReassignBu;
