import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CommonTitleBar from "../management/CommonTitleBar";
import styles from "../management/user.module.css";
import cx from "classnames";
import { create_role, CreateRole, store_roles, StoreUserRoles } from "../commonApi"; // Assume this function exists

 const Rolecreate = () => {
  const [rolename, setRolename] = useState("");
  const [permissions, setPermissions] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const navigate = useNavigate();
  const { organization } = useParams();


  useEffect(() => {
    fetchPermissions();
  }, []);

  function commonHeader() {
    let token = JSON.parse(localStorage.getItem("genAi"));
    let Header = { headers: { Authorization: `Bearer ${token}` } };
    return Header;
  }

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(
        "http://172.31.75.131:9098/v1/get_permission/testaide",
        commonHeader()
      );
      if (response.data.success) {
        const permissionsData = response.data.data;

        // Initialize selectedPermissions with all values set to false
        const initialPermissions = {};
        Object.keys(permissionsData).forEach((category) => {
          initialPermissions[category] = {};
          Object.keys(permissionsData[category]).forEach((key) => {
            initialPermissions[category][key] = false; // Set everything to false initially
          });
        });

        setPermissions(permissionsData); // Store the fetched permissions
        setSelectedPermissions(initialPermissions); // Initialize all permissions to false
      } else {
        console.error("Failed to fetch permissions");
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handlePermissionChange = (category, key) => (event) => {
    const { checked } = event.target;

    setSelectedPermissions((prevPermissions) => ({
      ...prevPermissions,
      [category]: {
        ...prevPermissions[category],
        [key]: checked, // Update the permission based on user selection
      },
    }));
  }; // Import your API methods

  async function createRoleBtn(e) {
    e.preventDefault();
  
    // Check if role name is provided
    if (!rolename) {
      alert("Please enter a role name");
      return;
    }
  
    try {
      // Call API 1: Create Role
      const roleResponse = await CreateRole({ role_name: rolename }, organization);
  
      if (roleResponse && roleResponse.role_id) {
        const roleId = roleResponse.role_id;
        const UserId=roleResponse.user_id;
  
        // Call API 2: Store User Role
        const userRoleResponse = await StoreUserRoles(
          { role_id: roleId, user_id: UserId }, // Replace with actual user_id
          organization
        );
  
        if (userRoleResponse.success) {
          // Prepare the permissions data with all values (ticked as true, unticked as false)
          const formattedPermissions = {};
          Object.keys(selectedPermissions).forEach((category) => {
            formattedPermissions[category] = {};
            Object.keys(selectedPermissions[category]).forEach((key) => {
              formattedPermissions[category][key] = selectedPermissions[category][key] || false;
            });
          });
  
          // Create role permissions object with nested structure
          const rolePermissions = {
            roles: [
              {
                role_name: rolename,
                permissions: formattedPermissions, // Include all permissions, setting unticked to false
              },
            ],
          };
  
          // Call API 3: Store Roles with Permissions
          const storeRolesResponse = await store_roles(rolePermissions, organization);
  
          if (storeRolesResponse.success) {
            // Role creation and permission assignment successful
            navigate(`/roleManagement/${organization}`);
          } else {
            console.error("Failed to store role permissions");
          }
        } else {
          console.error("Failed to assign role to user");
        }
      } else {
        console.error("Failed to create role");
      }
    } catch (error) {
      console.error("Error during role creation or permission assignment", error);
    }
  }
  

  return (
    <div>
      <CommonTitleBar />
      <div className={styles.inside_newuser}>
        <form>
          <div className={styles.d_flex}>
            <label className={styles.role}>Role Name</label>
            <input
              autoComplete="off"
              type="text"
              value={rolename}
              onChange={(e) => setRolename(e.target.value)}
              className={styles.roleinput}
            />
          </div>

          <div>
            <div className={styles.table_width50}>
              <div className={styles.module_right}>Modules Available</div>
              <div className={styles.policyspace}>Policies</div>
            </div>

            {permissions && Object.keys(permissions).length > 0 ? (
              Object.keys(permissions).map((category, categoryIndex) => (
                <div className={styles.insidetable_width50} key={categoryIndex}>
                  <div className={styles.left_width40}>
                    <label>{category}</label> {/* Render the category name */}
                  </div>
                  <div className={styles.right_width60}>
                    {Object.entries(permissions[category]).map(
                      ([key, label], index) => (
                        <div className={styles.eachcheck} key={index}>
                          <input
                            autoComplete="off"
                            type="checkbox"
                            name={key} // Use the permission key as the input name
                            className={cx(
                              "radioChecking1 policesName",
                              styles.radioChecking,
                              styles.adioChecking1
                            )}
                            checked={
                              selectedPermissions[category]?.[key] || false
                            } // Check based on selected permissions
                            onChange={handlePermissionChange(category, key)} // Handle permission change
                          />
                          <label htmlFor="">{label}</label>{" "}
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No permissions available</p> // Show this message if no permissions available
            )}
          </div>

          <div className={styles.create_btn}>
            <div
              className={styles.commonbtn}
              type="submit"
              onClick={(e) => createRoleBtn(e)}
            >
              Create user role
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};


export default Rolecreate;
