import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import styles from "../../screens/hubplus/index.module.css";
import styles from "./user.module.css";
// import plus from "../assests/images/logo/plus.png";

const CommonTitleBar = () => {
  let a1 = window.location.href.split("/");
  console.log(a1[3]);
  
const {organization}= useParams();


  return (
    <div className={styles.main}>
      <Link to={`/userManagement/${organization}`}>
        <h4
          className={`${styles.page_tittle} ${
            a1[3] === "userManagement" ? "green" : ""
          } `}
        >
          USER MANAGEMENT
        </h4>
      </Link>
      <Link to={`/roleManagement/${organization}`}>
        <h4
          className={`${styles.page_tittle} ${
            a1[3] === "roleManagement" ? "green" : ""
          } `}
        >
          ROLE MANAGEMENT
        </h4>
      </Link>
      <Link to={`/businessManagement/${organization}`}>
        <h4
          className={`${styles.page_tittle} ${
            a1[3] === "businessManagement" ? "green" : ""
          } `}
        >
          BUSINESS UNIT    
        </h4>
      </Link>
      <Link to={`/userManagementIdp/${organization}`}>
        <h4
          className={`${styles.page_tittle} ${
            a1[3] === "UserGroups" ? "green" : ""
          } `}
        >
         IDP  
        </h4>
      </Link>
      {/* <Link to={`/ResourceGroup/${organization}`}>
        <h4
          className={`${styles.page_tittle} ${
            a1[3] === "ResourceGroup" ? "green" : ""
          } `}
        >
         Resource Group  
        </h4>
      </Link> */}
    </div>
  );
};

export default CommonTitleBar;
