import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CheckIdp,
  editBusinessunitadmin,
  Get_business_units,
} from "../../commonApi";
import CommonTitleBar from "../CommonTitleBar";
import styles from "../businessmanagement/business.module.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import close from "../../assests/images/logo/close.png";
import files from "../../assests/images/logo/files.png";
import editify from "../../assests/images/logo/editify.png";
import del from "../../assests/images/logo/del.png";
import options from "../../assests/images/logo/moreoptions.png";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import searchicon from "../../assests/images/logo/searchIcon.png";
import BusinessunitPopup from "./BusinessunitPopup";
import ReassignBu from "./ReassignBu";

const BusinessManagement = () => {
  const { organization } = useParams();
  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [modopen, setModopen] = useState(false);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUnitForDeletion, setSelectedUnitForDeletion] = useState(null); // Trac

  const [modopen2, setModopen2] = useState(false);

  const [newBuName, setNewBuName] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState("");

  const handlemodClose2 = () => setModopen2(false);
  // const handleChange = (event) => setAdminName(event.target.value);

  const BusinessUnitEdit = async (event) => {
    event.preventDefault();
    console.log("Edit Business Unit");

    // Check if bu name and admin are selected
    if (!newBuName || !selectedAdmin) {
      alert("Please fill all fields");
      return;
    }
    const [id, user_id, bu_id] = selectedAdmin.split("|");

    try {
      const body = {
        bu_name: newBuName, // Pass the new business unit name
        user_id: user_id, // Pass the selected admin's user_id
        bu_id: parseInt(bu_id, 10),
      };

      // Start loading indicator

      // Call the API
      const response = await editBusinessunitadmin(body, organization);

      if (response.success) {
        console.log("Business unit edited successfully", response);
        fetchBusinessUnits();

        handlemodClose2(); // Close the modal after success
      } else {
        console.error("Failed to edit business unit", response.message);
      }
    } catch (error) {
      console.error("Error during business unit edit", error);
    } finally {
      // Stop loading indicator
    }
  };

  // Function to handle admin selection from the dropdown
  const handleAdminChange = (event) => {
    setSelectedAdmin(event.target.value); // Set the selected admin ID
  };
  const handlemodOpen2 = () => {
    setModopen2(true); // Open the modal
  };

  const handleDeleteClick = (unit) => {
    setSelectedUnitForDeletion(unit);
    setShowPopup(true); // Show popup
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleConfirmAssign = () => {
    console.log("Business unit assigned!"); // You can add your logic here
    setShowPopup(false); // Close popup after confirming
  };

  // Function to confirm deletion
  const handleConfirmDelete = () => {
    console.log("Deleting unit:", selectedUnitForDeletion);
    // Add your logic to delete the business unit here
    setShowPopup(false); // Hide popup after confirming
  };
  const handleOptionsClick = () => {
    setShowPopup(true); // Show the popup
  };

  useEffect(() => {
    if (organization) {
      fetchBusinessUnits();
      fetchCheckIdp();
    }
  }, [organization]);

  const fetchBusinessUnits = async () => {
    const businessData = await Get_business_units(organization);
    if (businessData.success) {
      setBusinessUnits(businessData.data);
    } else {
      console.error("Failed to fetch business units");
    }
  };

  const fetchCheckIdp = async () => {
    try {
      const checkidp = await CheckIdp(organization); // Call the API to check the IDP status
      if (checkidp.success) {
        // If the response is successful, navigate to the UserGroups page
        // navigate(`/UserGroups/${organization}`);
      } else {
        // If the response is not successful, handle the error (stay on the page)
        console.error("Credentials not exist:", checkidp.message);
        // Optionally, you can show an alert or a message on the page
        alert(checkidp.message || "Failed to fetch IDP credentials.");
      }
    } catch (error) {
      console.error("Error fetching IDP credentials:", error);
      // Optionally, handle any network or server errors here
    }
  };
  useEffect(() => {
    if (businessUnits.length > 0) {
      const [firstUnit] = Object.entries(businessUnits[0]);
      setSelectedUnit({ [firstUnit[0]]: firstUnit[1] });
    }
  }, [businessUnits]);

  const handleUnitClick = (unit) => {
    setSelectedUnit(unit);
  };

  const handlemodOpen = () => {
    navigate(`/UserGroups/${organization}`)
    setModopen(true);
  };
  const handlemodClose = () => setModopen(false);

  return (
    <div>
      <CommonTitleBar />
      <div className={styles.allroles}>
        <div className={styles.busflex}>
          <div className={styles.busfull}>
            <div className={styles.commonbtn1}>
              <span onClick={handlemodOpen}>New Business Unit</span>
            </div>
          </div>
        </div>
        <div className={styles.metrics}>
          <div className={styles.runns}>
            {businessUnits.map((unitObj, index) => {
              const [unitName, unitData] = Object.entries(unitObj)[0];
              const isSelected = selectedUnit && selectedUnit[unitName];
              return (
                <div
                  key={index}
                  className={styles.runids}
                  onClick={() => handleUnitClick(unitObj)}
                  style={{ opacity: isSelected ? 1 : 0.5 }}
                >
                  <div className={styles.firstrow}>
                    <div className={styles.insiderow}>
                      <div className={styles.sty}>
                        <img src={files} className={styles.fol} alt="" />
                      </div>
                      <p>{unitName}</p>
                    </div>
                    <div className={styles.edidel}>
                      <div>
                        <img
                          src={editify}
                          className={styles.fol}
                          alt=""
                          onClick={handlemodOpen2}
                        />
                        <Modal
                          open={modopen2}
                          onClose={handlemodClose2}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className={styles.innermodel}>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <div className={styles.heade}>
                                <div className={styles.heading}>
                                  <p>Edit Business Unit</p>
                                </div>
                                <div>
                                  <img
                                    src={close}
                                    onClick={handlemodClose2}
                                    className={styles.closex}
                                    alt="closer"
                                  />
                                </div>
                              </div>
                              {/* <Charging open={loading} /> */}
                              <form
                                onSubmit={BusinessUnitEdit}
                                className={styles.list}
                              >
                                <div>
                                  <input
                                    type="text"
                                    required
                                    placeholder="Enter Business Unit Name"
                                    value={newBuName}
                                    onChange={(e) =>
                                      setNewBuName(e.target.value)
                                    }
                                    className={styles.ip1}
                                  />
                                </div>

                                <div>
                                  <label
                                    className={styles.lab}
                                    id="Select User"
                                  >
                                    Bu Admin
                                  </label>
                                  <Select
                                    className={styles.dDown}
                                    labelId="Select User"
                                    value={selectedAdmin}
                                    onChange={handleAdminChange}
                                    required
                                  >
                                    {businessUnits &&
                                    businessUnits.length > 0 ? (
                                      businessUnits.map((unitObj, index) => {
                                        const [unitName, unitData] =
                                          Object.entries(unitObj)[0];
                                        return unitData.map((user) => (
                                          <MenuItem
                                            key={user.id}
                                            value={`${user.id}|${user.user_id}|${user.bu_id}`}
                                          >{`${user.name} - ${user.email}`}</MenuItem>
                                        ));
                                      })
                                    ) : (
                                      <MenuItem key="no-user" value="" disabled>
                                        No business units available
                                      </MenuItem>
                                    )}
                                  </Select>
                                </div>

                                <div>
                                  <button
                                    type="submit"
                                    className={styles.subbtn}
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              </form>
                            </Typography>
                          </Box>
                        </Modal>
                      </div>
                      <div>
                        <img
                          src={del}
                          className={styles.fol}
                          alt=""
                          onClick={() => handleDeleteClick(unitObj)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {selectedUnit && (
            <div className={styles.paramets}>
              <div className={styles.fixe}>
                <table>
                  <thead className={styles.fillblue}>
                    <tr>
                      <th>NAME</th>
                      <th>USER ID</th>
                      <th>BU NAME</th>
                      <th>EMAIL ID</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(selectedUnit)[0].map((user, index) => (
                      <tr key={index}>
                        <td className={styles.insbox}>{user.name}</td>
                        <td className={styles.insbox}>{user.user_id}</td>
                        <td className={styles.insbox}>{user.bu_name}</td>
                        <td className={styles.insbox}>{user.email}</td>

                        <td className={styles.insbox}>
                          <img
                            src={options}
                            className={styles.Ins}
                            onClick={handleOptionsClick}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <BusinessunitPopup
            show={showPopup}
            onClose={handleClosePopup}
            onConfirm={handleConfirmDelete}
            msgs="This Business Unit has users assigned."
          />
          <ReassignBu
            show={showPopup} // Conditionally render based on state
            onClose={handleClosePopup} // Close function passed to ReassignBu
            onConfirm={handleConfirmAssign} // Confirm function passed to ReassignBu
            user={"staticUserId"} // Static user ID for now
            p_role={"staticRole"} // Static role for now
          />
          {/* <Modal
            open={modopen}
            onClose={handlemodClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={styles.innermodel}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className={styles.heade}>
                  <div className={styles.heading}>
                    <p>Create Business Unit</p>
                  </div>
                  <div>
                    <img
                      src={close}
                      onClick={() => setModopen(false)}
                      className={styles.closex}
                      alt="closer"
                    />
                  </div>
                </div>
                <form action="" className={styles.list}>
                  <input
                    type="text"
                    required
                    autoComplete="off"
                    placeholder="Enter Business Unit Name"
                    className={styles.ip1}
                  />
                  <Dropdown
                        required
                        placeholder="Assigned Role"
                        className={styles.dDown}
                      ></Dropdown>
                  <div className={styles.formContainer}>
                    <div className={styles.relativeContainer}>
                      <input
                        type="text"
                        required
                        autoComplete="off"
                        placeholder="Assign User"
                        className={styles.searchbtn}
                      />
                      <img
                        src={searchicon}
                        alt="Search Icon"
                        className={styles.searchIcon}
                      />
                      <img
                        src={close}
                        alt="Close Icon"
                        className={styles.closeIcon}
                      />
                    </div>
                  </div>
                  <button className={styles.subbtn}>Create</button>
                </form>
                <div className={styles.line}></div>
                <div className={styles.users}>
                  <p className={styles.selected}>Steve@gmail.com</p>
                  <img
                    src={close}
                    alt="Close Icon"
                    className={styles.clearicon}
                  />
                </div>
              </Typography>
            </Box>
          </Modal> */}
        </div>
      </div>
    </div>
  );
};

export default BusinessManagement;
