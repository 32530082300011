import React, { useEffect, useState, useRef } from "react";
import styles from "./Resource.module.css";
import openai from "../assests/images/hubplusicons/openai.svg";
import git from "../assests/images/hubplusicons/git.png";
import CommonTitleBar from "../management/CommonTitleBar";
import remove from "../assests/images/logo/remove.png";
// import addRemove from "../../../src/assests/images/logo/add-remove.svg";
import {
  GetResourceGroup,
  GetResourceGroupCredentials,
  GetResourceGroupData,
  GetResourceGroupUsers,
  PostAssignUser,
  postOpenAi,
  PostResourceGroup,
} from "../commonApi";
import { useParams } from "react-router-dom";

// Create a reusable Modal component
const Modal = ({ show, handleClose, title, children }) => {
  if (!show) return null;

  return (
    <div className={styles.modalBackdrop} onClick={handleClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h2>{title}</h2>
          <button className={styles.closeButton} onClick={handleClose}>
            &times;
          </button>
        </div>
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );
};

const ResourceGroup = () => {
  // State to control the visibility of different modals
  const [isDataSourceModalOpen, setIsDataSourceModalOpen] = useState(false);
  const [isAddDataSourceModalOpen, setIsAddDataSourceModalOpen] =
    useState(false);
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [isCredentialModalOpen, setIsCredentialModalOpen] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [OpenAi, setOpenAi] = useState("");
  const [openAikey, setOpenAiKey] = useState("");
  const [dataSources, setDataSources] = useState([]);
  const [availableDataSources] = useState(["Open Ai"]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataSourcescred, setDataSourcesCred] = useState([]);
  const [dataSourcesdata, setDataSourcesData] = useState([]);
  const [membersData, setMembersData] = useState({
    assigned: [],
    unassigned: [],
  });

  const [selectedResourceName, setSelectedResourceName] = useState("");
  const [selectedCredentials, setSelectedCredentials] = useState([]); // State for storing selected credentials
  const [resourceName, setResourceName] = useState("");

  // State for the inline popup
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({});
  const popupRef = useRef(null);
  const { organization } = useParams();

  const [assignedMembers, setAssignedMembers] = useState(["User 1", "User 2"]);
  const [unassignedMembers, setUnassignedMembers] = useState([
    "User 3",
    "User 4",
    "User 5",
  ]);

  const [selectedAssigned, setSelectedAssigned] = useState([]);
  const [selectedUnassignedUsers, setSelectedUnassignedUsers] = useState([]);
  const [selectedResourceId, setSelectedResourceId] = useState("");

  useEffect(() => {
    fetchDatasources();
    fetchDatasourcesCredentials();
    fetchDatasourcesData();
  }, []);

  const handleCheckboxChange = (credId) => {
    setSelectedCredentials((prevSelected) =>
      prevSelected.includes(credId)
        ? prevSelected.filter((id) => id !== credId)
        : [...prevSelected, credId]
    );
  };

  const handleCreateResourceGroup = async () => {
    if (!resourceName) {
      alert("Please provide a resource group name.");
      return;
    }

    const data = {
      resource_name: resourceName,
      credentials: selectedCredentials,
    };

    try {
      const response = await PostResourceGroup(data, organization);
      if (response.success) {
        alert("Resource group created successfully!");
        fetchDatasourcesData();

        setIsResourceModalOpen(false);
      } else {
        alert(response.message || "Failed to create resource group.");
      }
    } catch (error) {
      console.error("Error creating resource group:", error);
      alert("An error occurred while creating the resource group.");
    }
  };

  const handleOpenDataSourceModal = () => {
    setIsDataSourceModalOpen(true);
  };

  const handleOpenCredentialModal = (dataSource) => {
    setSelectedDataSource(dataSource); // Set the selected data source
    setIsCredentialModalOpen(true);
  };

  const handleOpenAddDataSourceModal = () => {
    setIsAddDataSourceModalOpen(true);
  };

  const handleOpenResource = () => {
    setIsResourceModalOpen(true);
  };

  const handleCloseCredentialModal = () => {
    setIsCredentialModalOpen(false);
  };
  const handleCloseDataSourceModal = () => {
    setIsDataSourceModalOpen(false);
  };

  const handleCloseresource = () => {
    setIsResourceModalOpen(false);
  };
  const fetchDatasources = async () => {
    try {
      const response = await GetResourceGroup();
      if (response.success) {
        setDataSources(response.data);
      } else {
        console.error("Failed to fetch data sources");
      }
    } catch (error) {
      console.error("Error fetching data sources:", error);
    }
  };

  const fetchDatasourcesCredentials = async () => {
    try {
      const response = await GetResourceGroupCredentials();
      if (response.success) {
        setDataSourcesCred(response.data);
      } else {
        console.error("Failed to fetch data sources");
      }
    } catch (error) {
      console.error("Error fetching data sources:", error);
    }
  };
  const fetchDatasourcesData = async () => {
    try {
      const response = await GetResourceGroupData(organization);
      if (response.success) {
        setDataSourcesData(response.resources);
      } else {
        console.error("Failed to fetch data sources");
      }
    } catch (error) {
      console.error("Error fetching data sources:", error);
    }
  };

  // const fetchDatasourcesUser = async () => {
  //   try {
  //     const response = await GetResourceGroupUsers(organization,);
  //     if (response.success) {
  //       setResourceUsers(response.resources);
  //     } else {
  //       console.error("Failed to fetch data sources");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data sources:", error);
  //   }
  // };
  const handleOpenPopup = async (ResourceName, resourceId) => {
    try {
      const response = await GetResourceGroupUsers(organization, resourceId);
      if (response.success) {
        setMembersData({
          assigned: response.assigned_user || [],
          unassigned: response.need_to || [],
        });
        setShowPopup(true);
        setSelectedResourceName(ResourceName);
        setSelectedResourceId(resourceId);
      } else {
        console.error("Failed to fetch resource group users");
      }
    } catch (error) {
      console.error("Error fetching resource group users:", error);
    }
  };

  const handleCloseAddDataSourceModal = () => {
    setIsAddDataSourceModalOpen(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleAddMembers = async () => {
    if (selectedUnassignedUsers.length === 0) {
      alert("Please select users to add.");
      return;
    }

    const data = {
      resource_id: selectedResourceId,
      users: selectedUnassignedUsers,
    };

    try {
      const response = await PostAssignUser(data);
      if (response.success) {
        alert("Users assigned successfully!");
        // Refresh the data to show updated assigned and unassigned members
        await handleOpenPopup(selectedResourceName, selectedResourceId);
      } else {
        alert(response.message || "Failed to assign users.");
      }
    } catch (error) {
      console.error("Error assigning users:", error);
      alert("An error occurred while assigning users.");
    }
  };

  const handleRemoveMembers = () => {
    setUnassignedMembers([...unassignedMembers, ...selectedAssigned]);
    setAssignedMembers(
      assignedMembers.filter((member) => !selectedAssigned.includes(member))
    );
    setSelectedAssigned([]);
  };

  const handleCheckboxChange2 = (memberId, listType) => {
    if (listType === "unassigned") {
      setSelectedUnassignedUsers((prevState) =>
        prevState.includes(memberId)
          ? prevState.filter((id) => id !== memberId)
          : [...prevState, memberId]
      );
    }
  };

  const handleCredentialSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: OpenAi, // OpenAi name
      openai_key: openAikey, // OpenAi Key
    };

    try {
      // Show loading state
      const response = await postOpenAi(data, organization); // Call the API

      if (response.success) {
        alert("Credentials saved successfully!");
        setIsAddDataSourceModalOpen(false);
        setIsCredentialModalOpen(false);
        setOpenAi("");
        setOpenAiKey("");
      } else {
        alert("Failed to save credentials. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting credentials:", error);
      alert("An error occurred. Please try again.");
    } finally {
      // Reset loading state
    }
  };

  const filteredDataSources = Object.entries(dataSources).filter(([name]) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <CommonTitleBar />
      <div>
        <div className={styles.nnnnn}>
          <div className={styles.data}>
            <button onClick={handleOpenDataSourceModal}>Keys</button>
            <button onClick={handleOpenAddDataSourceModal}>
              <h4>+</h4>
            </button>
          </div>
          <div className={styles.resour}>
            <button onClick={handleOpenResource} className={styles.commonbtn1}>
              Create Resource Group
            </button>
          </div>
        </div>
      </div>
      {/* Table Section */}
      <div className={styles.tableContainer}>
        <table className={styles.resourceTable}>
          <thead>
            <tr>
              <th>Resource Group</th>
              <th>Data Sources</th>
              <th>Assigned User</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(dataSourcesdata).length > 0 ? (
              Object.entries(dataSourcesdata).map(
                ([resourceGroup, resources]) =>
                  resources.map((resource, index) => (
                    <tr key={`${resourceGroup}-${index}`}>
                      <td>{resourceGroup}</td>
                      <td>{resource.resource_name}</td>
                      <td
                        onClick={() =>
                          handleOpenPopup(
                            resource.resource_name,
                            resource.cred_id
                          )
                        }
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        Members
                      </td>
                    </tr>
                  ))
              )
            ) : (
              <tr>
                <td colSpan="3">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Inline Popup */}
      {showPopup && (
        <div className={styles.popupContainer}>
          <div className={styles.popupContent}>
            <h5 className={styles.head}>Members for {selectedResourceName}</h5>

            <div className={styles.remove}>
              <img
                src={remove}
                className={styles.im}
                onClick={handleClosePopup}
                alt="close"
              />
              <div className={styles.butto}>
                <button
                  className={styles.commonbtn2}
                  onClick={() => alert("Remove functionality not implemented")}
                >
                  Remove
                </button>
                <button
                  className={styles.commonbtn1}
                  onClick={handleAddMembers}
                >
                  Add
                </button>
              </div>
            </div>

            <div className={styles.popupMembersContainer}>
              {/* Assigned Members */}
              <div className={styles.popupSection}>
                <h6>Assigned Members</h6>
                <input
                  type="text"
                  className={styles.inp}
                  placeholder="Search assigned members"
                />
                {membersData.assigned.map((member, index) => (
                  <div key={index} className={styles.assign}>
                    <input
                      type="checkbox"
                      checked={false} // assigned members are not selectable for adding
                      disabled
                    />
                    {member.first_name} {member.last_name || ""}
                  </div>
                ))}
              </div>

              {/* Unassigned Members */}
              <div className={styles.popupSection}>
                <h6>Unassigned Members</h6>
                <input
                  type="text"
                  className={styles.inp}
                  placeholder="Search unassigned members"
                />
                {membersData.unassigned.map((member, index) => (
                  <div key={index} className={styles.assign}>
                    <input
                      type="checkbox"
                      checked={selectedUnassignedUsers.includes(member.user_id)}
                      onChange={() =>
                        handleCheckboxChange2(member.user_id, "unassigned")
                      }
                    />
                    {member.first_name} {member.last_name || ""}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for displaying Data Sources */}
      <Modal
        show={isDataSourceModalOpen}
        handleClose={handleCloseDataSourceModal}
        title="Data Sources"
      >
        <div>
          <input
            type="text"
            placeholder="Search"
            className={styles.inp}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* List the data sources dynamically */}
        <ul className="space-y-2">
          {Object.keys(dataSourcescred).length > 0 ? (
            Object.entries(dataSourcescred).map(([name, credentials]) => (
              <li key={name}>
                <strong>{name}</strong>:
                {credentials && credentials.length > 0 ? (
                  <ul className={styles.credentialList}>
                    {credentials.map((credential) => (
                      <li key={credential.cred_id}>
                        {credential.credential_name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  " No credentials available"
                )}
              </li>
            ))
          ) : (
            <li>No data sources available</li>
          )}
        </ul>
      </Modal>
      <Modal
        show={isAddDataSourceModalOpen}
        handleClose={handleCloseAddDataSourceModal}
        title="Add Data Sources"
      >
        <div>
          <input type="text" placeholder="Search" className={styles.inp} />
        </div>
        {/* List available data sources dynamically */}
        <ul className={styles.dataSourceList2}>
          {availableDataSources.map((dataSource, index) => (
            <li
              key={index}
              onClick={() => handleOpenCredentialModal(dataSource)}
            >
              <img src={openai} alt={dataSource} /> {dataSource}
            </li>
          ))}
        </ul>
      </Modal>
      <Modal
        show={isCredentialModalOpen}
        handleClose={handleCloseCredentialModal}
        title={` ${selectedDataSource} Credentials`}
      >
        <form
          className={styles.credentialForm}
          // onSubmit={handleCredentialSubmit}
        >
          {selectedDataSource === "Open Ai" && (
            <div className={styles.cred}>
              <div className={styles.formGroup}>
                <label>Name</label>
                <input
                  type="text"
                  value={OpenAi}
                  className={styles.inp2}
                  onChange={(e) => setOpenAi(e.target.value)}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Open Ai Key :</label>
                <input
                  type="text"
                  value={openAikey}
                  onChange={(e) => setOpenAiKey(e.target.value)}
                  className={styles.inp2}
                  required
                />
              </div>
            </div>
          )}
          {/* Other credential forms can be added here based on the selected data source */}
          <div className={styles.modalActions}>
            <button
              onClick={handleCredentialSubmit}
              className={styles.submitButton}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        show={isResourceModalOpen}
        handleClose={handleCloseresource}
        title="Create Resource Group"
      >
        <div>
          <input
            type="text"
            placeholder="Enter Resource Group Name"
            className={styles.inp}
            value={resourceName}
            onChange={(e) => setResourceName(e.target.value)}
          />
        </div>

        {/* List the data sources dynamically */}
        <ul className="space-y-2">
          {Object.keys(dataSourcescred).length > 0 ? (
            Object.entries(dataSourcescred).map(([name, credentials]) => (
              <li key={name}>
                <strong>{name}</strong>:
                {credentials && credentials.length > 0 ? (
                  <ul className={styles.credentialList}>
                    {credentials.map((credential) => (
                      <h6 key={credential.cred_id}>
                        <input
                          type="checkbox"
                          onChange={() =>
                            handleCheckboxChange(credential.cred_id)
                          }
                        />{" "}
                        {credential.credential_name}
                      </h6>
                    ))}
                  </ul>
                ) : (
                  " No credentials available"
                )}
              </li>
            ))
          ) : (
            <li>No data sources available</li>
          )}
        </ul>
        <button
          className={styles.commonbtn}
          onClick={handleCreateResourceGroup}
        >
          Create
        </button>
      </Modal>
    </div>
  );
};

export default ResourceGroup;
