import React, { useRef, useState } from "react";
import Appcss from "./register.module.css";
import robot from "../assests/images/auth/Robot.png";
import logo from "../assests/images/header/genaiLogo.png";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Link, useNavigate, useParams } from "react-router-dom";
import { backend_url } from "../config";
import Charging from "../Charging";
import { Toast } from "primereact/toast";
import cx from "classnames";
import Robot from "../Charging/Robot";

const Register = () => {
  const [country, setCountry] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [eye, setEye] = useState(true);
  const toast = useRef(null);

  const {organization}=useParams();
  
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",

    organization: organization,

    email: "",
    password: "",
    confirm_password: "",
  });

  function changeHandler(e) {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  }

  async function submitForm(e) {
    try {
      e.preventDefault();
  
      // Check if passwords match
      if (user.password !== user.confirm_password) {
        showError("Passwords do not match");
        return; // Stop submission if passwords don't match
      }
  
      // Check if the email is an organizational email
      if (
        user.email.includes("gmail") ||
        user.email.includes("yahoo") ||
        user.email.includes("outlook") ||
        user.email.includes("protonmail") ||
        user.email.includes("zoho") ||
        user.email.includes("icloud")
      ) {
        console.log("only organization mail id should be there");
        showError("Use Only Organization mail_id");
        setUser({ ...user, email: "" });
      } else {
        let newData = user;
        setOpen(true);
  
        // API call to create the account
        const res = await axios.post(
          `http://54.85.255.13:9095/create-account/llmops/${organization}`,
          newData
        );
  
        setOpen(false);
  
        // Handle success or failure based on response
        if (res.data.success) {
          navigate("/registerSuccess");
        } else {
          showError(res.data.message || "An error occurred. Please try again.");
        }
      }
    } catch (e) {
      setOpen(false);
  
      // Check if error response exists and has a message
      if (e.response && e.response.data && e.response.data.message) {
        showError(e.response.data.message); // Display the message from the backend
      } else {
        console.log("There is an Error--", e.message);
        showError("Something went wrong. Please try again later.");
      }
    }
  }
  
  const showError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 4000,
    });
  };
  const handleLoginClick = () => {
    navigate(`/TestingAide/${organization}`);
  };

  return (
    <>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={cx(Appcss.bdy, "row")}>
        <div className={cx(Appcss.firsthalf, "col-lg-6")}>
          <h2 className={Appcss.headline}>Bring your Data Science to Life</h2>
          <div className={Appcss.robo}>
            <Robot />
          </div>

          {/* <img className={Appcss.robo} src={robot} alt="Robot" /> */}
        </div>

        <div className={cx(Appcss.secondhalf, "col-lg-6")}>
          <img className={Appcss.logo} src={logo} alt="logo" />
          <span className={Appcss.registerBig}>Register</span>
          <span className={Appcss.signup}>Signup to MLAngles|GenAI</span>

          <form className={Appcss.forms} onSubmit={submitForm}>
            <div className={Appcss.details1}>
              <input
                className={Appcss.c1}
                placeholder="First Name*"
                value={user.first_name}
                name="first_name"
                type="text"
                autoComplete="off"
                onChange={changeHandler}
                required
              />
              <input
                className={Appcss.c1}
                placeholder="Last Name*"
                value={user.last_name}
                name="last_name"
                onChange={changeHandler}
                type="text"
                required
                autoComplete="off"
              />
            </div>

            <div className={Appcss.details}>
              <input
                className={Appcss.detailsorg}
                placeholder="Organisation*"
                value={organization}
                name="organization"
                onChange={changeHandler}
                required
                type="text"
                autoComplete="off"
              />
              <input
                className={Appcss.c1}
                placeholder="Organisation Email*"
                value={user.email}
                name="email"
                type="email"
                onChange={changeHandler}
                required
                autoComplete="off"
              />
            </div>

            <div className={Appcss.details1}>
              <input
                className={Appcss.c1}
                placeholder="Password*"
                value={user.password}
                name="password"
                onChange={changeHandler}
                required
                type={eye ? "password" : "text"}
                autoComplete="off"
              />
              {eye ? (
                <i
                  onClick={() => setEye(!eye)}
                  className={cx("fa-solid fa-eye-slash", Appcss.eye)}
                ></i>
              ) : (
                <i
                  onClick={() => setEye(!eye)}
                  className={cx("fa-solid fa-eye", Appcss.eye)}
                ></i>
              )}

              <input
                className={Appcss.c1}
                placeholder="Confirm Password*"
                value={user.confirm_password}
                name="confirm_password"
                onChange={changeHandler}
                type="password"
                required
                autoComplete="off"
              />
            </div>
            <div className={Appcss.line}>
              <input
                className={Appcss.check}
                required
                type="checkbox"
                id="tick"
              />
              <label className={Appcss.tick} htmlFor="tick">
                Accept
                <a className={Appcss.register} href="">
                  Terms &amp; Conditions
                </a>
              </label>
            </div>
            <div className={Appcss.registerbtn}>
              <button className={Appcss.register_btn} type="submit">
                Register
              </button>
            </div>
            <div>
              <p className={Appcss.member}>
                Already have a account?
                <span  onClick={handleLoginClick} className={Appcss.register_now}>
                  Go to Login
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
