import React, { useState, useEffect, useRef } from "react";
import styles from "../UsermanagementIdp/usergroups.module.css"; // Import your CSS module
import arrow from "../assests/images/hubplusicons/arrow.png";
import checkmark from "../assests/images/hubplusicons/checkmark.png";

import {
  AssignBusinessusers,
  GetAdusers,
  GetBusinessUsers,
  PostBusinessusers,
  StoreBusinessusers,
  storebudata,
} from "../commonApi";
import { Navigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useNavigate } from 'react-router-dom';

import Charging from "../Charging";
import CommonTitleBar from "../management/CommonTitleBar";

const UserGroups = () => {
  // State to track the active tab: "usergroups" or "users"
  
  const [activeTab, setActiveTab] = useState("usergroups");
  const [adUsers, setAdUsers] = useState([]);
  const { organization } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [businessUnitName, setBusinessUnitName] = useState(""); // State for Business Unit Name
  const [adminSearch, setAdminSearch] = useState(""); // State for Admin Name search
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]); // State for selected user IDs
  const [selectedBusinessUnitId, setSelectedBusinessUnitId] = useState(null); // State for selected business unit ID
  const [assignedBusinessUnits, setAssignedBusinessUnits] = useState([]); // State to track assigned business units and users

  // Fetch AD Users and Business Units from API

  // Function to handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Function to open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (organization) {
      fetchAdUsers();
      fetchBusinessUsers();
    }
  }, [organization]);

  const fetchAdUsers = async () => {
    const usersData = await GetAdusers(organization);
    if (usersData && usersData.success) {
      setAdUsers(usersData.departments); // Store the fetched users in state
    } else {
      console.error("Failed to fetch AD users");
    }
  };

  const handleCreate = async () => {
    if (assignedBusinessUnits.length === 0) {
      alert("No business units assigned to users.");
      return;
    }
  
    // Create the payload in the desired format
    const formattedData = {
      user_data: assignedBusinessUnits.reduce((acc, assignment) => {
        // Assign users to their business unit names
        acc[assignment.bu_name] = assignment.users.map((user) => {
          // Find user details by their displayName
          const userDetails = Object.values(adUsers)
            .flat()
            .find((u) => u.displayName === user);
          return {
            id: userDetails.id,
            displayName: userDetails.displayName,
            mail: userDetails.mail,
            userPrincipalName: userDetails.userPrincipalName,
            department: userDetails.department,
          };
        });
        return acc;
      }, {}),
    };
  
    try {
      // Call the first API (StoreBusinessusers)
      const response1 = await StoreBusinessusers(formattedData, organization);
  
      // Call the second API (storebudata) with the same data
      const response2 = await storebudata(formattedData, organization);
  
      // Check if both API responses were successful
      if (response1.success && response2.success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Business units and users successfully created!",
          life: 3000,
        });
        navigate(`/userManagement/${organization}`);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to create business units and users.",
          life: 4000,
        });
      }
    } catch (error) {
      console.error("Error storing business users:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while creating business units.",
        life: 4000,
      });
    }
  };
  
  
  const handleAdminSearchChange = (e) => {
    const searchValue = e.target.value;
    setAdminSearch(searchValue);

    // If the search value is empty, reset the filtered users
    if (!searchValue) {
      setFilteredUsers([]);
      return;
    }

    // Flatten the adUsers structure to get all users across departments
    const allUsers = Object.values(adUsers).flat();

    // Filter users based on the search value
    const matchingUsers = allUsers.filter((user) =>
      user.mail.toLowerCase().includes(searchValue.toLowerCase())
    );

    // Update the filtered users state with matching results
    setFilteredUsers(matchingUsers);
  };

  const handleSelectSuggestion = (mail) => {
    // Find the user object by the selected email
    const selectedUser = Object.values(adUsers)
      .flat()
      .find((user) => user.mail === mail);

    if (selectedUser) {
      setAdminSearch(mail); // Set the adminSearch field to the selected email
      setSelectedAdminId(selectedUser.id); // Store the selected user's id as admin_id
    }

    setFilteredUsers([]); // Clear suggestions after selection
  };

  const fetchBusinessUsers = async () => {
    const BusinessData = await GetBusinessUsers();
    if (BusinessData && BusinessData.success) {
      setBusinessUnits(BusinessData.data); // Store business units in state
    } else {
      console.error("Failed to fetch Business Units");
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Handle user selection change (checkbox)
  const handleUserSelection = (userId) => {
    setSelectedUserIds(
      (prevSelected) =>
        prevSelected.includes(userId)
          ? prevSelected.filter((id) => id !== userId) // Remove if already selected
          : [...prevSelected, userId] // Add if not selected
    );
  };

  // Handle business unit selection
  const handleBusinessUnitSelection = (businessUnitId) => {
    setSelectedBusinessUnitId(businessUnitId);
  };

  // Handle assigning users to business unit
  const handleAssignBusinessUnit = () => {
    if (!selectedBusinessUnitId) {
      alert("Please select a business unit to assign.");
      return;
    }

    if (selectedUserIds.length === 0) {
      alert("Please select at least one user to assign.");
      return;
    }

    // Get the selected business unit details
    const selectedBusinessUnit = businessUnits.find(
      (unit) => unit.id === selectedBusinessUnitId
    );

    // Create a new assignment entry
    const newAssignment = {
      bu_id: selectedBusinessUnitId,
      bu_name: selectedBusinessUnit.name,
      users: selectedUserIds.map((userId) => {
        const user = Object.values(adUsers)
          .flat()
          .find((user) => user.id === userId);
        return user ? user.displayName : "";
      }),
    };

    // Update the assignedBusinessUnits state
    setAssignedBusinessUnits((prevAssignments) => {
      // Check if this business unit already has assignments
      const existingAssignment = prevAssignments.find(
        (assignment) => assignment.bu_id === selectedBusinessUnitId
      );

      if (existingAssignment) {
        // Update existing assignment by adding new users (avoid duplicates)
        return prevAssignments.map((assignment) =>
          assignment.bu_id === selectedBusinessUnitId
            ? {
                ...assignment,
                users: Array.from(
                  new Set([...assignment.users, ...newAssignment.users])
                ),
              }
            : assignment
        );
      } else {
        // Add new assignment
        return [...prevAssignments, newAssignment];
      }
    });

    // Clear selected users and business unit
    setSelectedUserIds([]);
    setSelectedBusinessUnitId(null);
  };

  const handleFormSubmitBusiness = async (event) => {
    event.preventDefault();

    // Prepare the data payload to send in the POST request
    const businessData = {
      data: [
        {
          business_unit: businessUnitName,
          user_id: selectedAdminId,
        },
      ],
    };

    try {
      setOpen(true);
      const response = await PostBusinessusers(businessData);
      console.log("Business Unit Creation Response:", response);

      if (response.success) {
        fetchBusinessUsers();

        setBusinessUnitName("");
        setAdminSearch("");
        toast.current.show({
          severity: "success",
          summary: " created successfully",
          detail: "Business Unit created successfully!",
          life: 3000,
        });
        handleCloseModal(); // Close the modal after successful submission
      } else {
        toast.current.show({
          severity: "error",
          summary: " Failed to Create Business Unit",
          detail: response.message,
          life: 4000,
        });
      }
    } catch (error) {
      console.error("Error creating Business Unit:", error);
    }
    setOpen(false);
  };

  const handleDepartmentSelection = (department) => {
    const departmentUserIds = adUsers[department].map((user) => user.id);
    const allSelected = departmentUserIds.every((id) =>
      selectedUserIds.includes(id)
    );
  
    if (allSelected) {
      // If all users in the department are selected, deselect them
      setSelectedUserIds((prevSelected) =>
        prevSelected.filter((id) => !departmentUserIds.includes(id))
      );
    } else {
      // If not all are selected, select all users in the department
      setSelectedUserIds((prevSelected) => [
        ...prevSelected,
        ...departmentUserIds.filter((id) => !prevSelected.includes(id)),
      ]);
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <CommonTitleBar/>
      {/* <div className={styles.heading}>
        <h5>USER MANAGEMENT - ASSIGN USERS TO BUSINESS UNIT</h5>
      </div> */}

      {/* Tabs for UserGroups and Users */}
      <div className={styles.State}>
        {/* UserGroups Tab */}
        <h5
          className={`${styles.user} ${
            activeTab === "usergroups" ? styles.active : ""
          }`}
          onClick={() => handleTabChange("usergroups")}
        >
          UserGroups
        </h5>

        {/* Users Tab */}
        <h5
          className={`${styles.user1} ${
            activeTab === "users" ? styles.active : ""
          }`}
          onClick={() => handleTabChange("users")}
        >
          Users
        </h5>
      </div>

      {/* Conditional Rendering Based on Active Tab */}
      <div className={styles.content}>
        {activeTab === "usergroups" && (
          <div className={styles.main}>
            <div className={styles.left}>
            {adUsers &&
  Object.keys(adUsers).map((department, index) => (
    <div key={index} className={styles.div}>
      {/* Department Name as the header */}
      <h5>
        <input
          type="checkbox"
          checked={adUsers[department].every((user) => selectedUserIds.includes(user.id))} // Check if all users in department are selected
          onChange={() => handleDepartmentSelection(department)} // Handle department selection change
        />{" "}
        {department}
      </h5>
      {/* Users under the department */}
      <div className={styles.submain}>
        {adUsers[department].map((user) => (
          <label key={user.id}>
            <input
              type="checkbox"
              checked={selectedUserIds.includes(user.id)} // Check if user is selected
              onChange={() => handleUserSelection(user.id)} // Handle user selection change
            />{" "}
            {user.displayName}
          </label>
        ))}
      </div>
    </div>
  ))}
            </div>
            <div className={styles.middle}>
              <div className={styles.insidemid}>
                <input
                  className={styles.inpmid}
                  type="text"
                  placeholder="Search"
                />
              </div>
              <div className={styles.midside}>
                {businessUnits.map((business) => (
                  <h5
                    key={business.id}
                    className={`${styles.market} ${
                      selectedBusinessUnitId === business.id
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() => handleBusinessUnitSelection(business.id)} // Handle business unit selection
                  >
                    {business.name}{" "}
                    {selectedBusinessUnitId === business.id && (
                      <img
                        src={checkmark}
                        alt="Selected"
                        className={styles.checkmarkIcon}
                      />
                    )}
                  </h5>
                ))}
              </div>
              <div className={styles.comon}>
                <button
                  className={styles.business}
                  onClick={handleAssignBusinessUnit}
                >
                  Assign Business Unit
                </button>
                <button className={styles.business} onClick={handleOpenModal}>
                  + Create New Business Unit
                </button>
                {isModalOpen && (
                  <div className={styles.modalBackdrop}>
                    <div className={styles.modalContent}>
                      <h5>Create New Business Unit</h5>
                      <form
                        className={styles.fromm}
                        onSubmit={handleFormSubmitBusiness}
                      >
                        <div className={styles.modalField}>
                          <label>Business Unit Name</label>
                          <input
                            type="text"
                            value={businessUnitName}
                            onChange={(e) =>
                              setBusinessUnitName(e.target.value)
                            }
                            placeholder="Enter business unit name"
                            required
                          />
                        </div>
                        <div className={styles.modalField}>
                          <label>Business Unit Admin Name</label>
                          <input
                            type="text"
                            value={adminSearch}
                            onChange={handleAdminSearchChange}
                            placeholder="Search admin by email"
                            required
                          />
                          {/* Suggestions Dropdown */}
                          {filteredUsers.length > 0 && (
                            <div className={styles.suggestionsDropdown}>
                              {filteredUsers.map((user) => (
                                <div
                                  key={user.id}
                                  className={styles.suggestionItem}
                                  onClick={() =>
                                    handleSelectSuggestion(user.mail)
                                  }
                                >
                                  {user.mail}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className={styles.modalActions}>
                          <button type="submit" className={styles.submitButton}>
                            Create
                          </button>
                          <button
                            type="button"
                            className={styles.cancelButton}
                            onClick={handleCloseModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.right}>
              <div>
                <h5 className={styles.assigned}>Assigned Business Unit</h5>
              </div>
              <div className={styles.assign}>
                {/* Render the assigned business units and users */}
                {assignedBusinessUnits.map((assignment, index) => (
                  <div key={index} className={styles.assignedUnit}>
                    <h5>{assignment.bu_name}</h5>
                    <ul>
                      {assignment.users.map((user, idx) => (
                        <li className={styles.list} key={idx}>
                          {user}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            <button className={styles.business1} onClick={handleCreate}>Create</button>

              </div>
            </div>
          </div>
        )}

        {activeTab === "users" && (
          <div className={styles.main}>
            {/* Content for Users */}
            <div className={styles.left2}>
              <div className={styles.div}>
                <div className={styles.submain}>
                  {/* Render user checkboxes dynamically from the adUsers state */}
                  {Object.values(adUsers)
                    .flat() // Flatten the array to get all users across departments
                    .map((user, index) => (
                      <label key={user.id || index}>
                        <input type="checkbox" /> {user.displayName}
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div className={styles.right2}>
              <div>
                <h5 className={styles.assigned}>Assigned Business Unit</h5>
              </div>

              <div className={styles.assign2}>
                {/* Render assigned business units dynamically */}
                {Object.keys(adUsers).map((department, index) => (
                  <h5 key={index}>
                    {department} <img src={arrow} alt="Arrow Icon" />
                  </h5>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserGroups;
