import React, { useEffect, useState } from "react";
import styles from "../UsermanagementIdp/user.module.css";
import { CheckIdp, GetIdpInputs, PostStoreAd } from "../commonApi";
import Charging from "../Charging";
import {  useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const UsermanagementIdp = () => {
  
  
  const [checkidp, setCheckIdp] = useState(""); // State to handle selected IDP

  
  const [idp, setIdp] = useState(""); // State to handle selected IDP
  const [showComponent, setShowComponent] = useState(false); // State to show/hide component based on IDP selection
  const [idpData, setIdpData] = useState(null); // State to store API response data
  const [formData, setFormData] = useState({}); // State to store dynamic form input values
  const [open, setOpen] = useState(false);
  const { organization } = useParams();
  const navigate = useNavigate();

  // Use effect to call the API on component mount
  useEffect(() => {
    async function fetchData() {
      const response = await idinputs();
      if (response && response.success) {
        setIdpData(response.data); // Store the "data" object from the API response
      }
    }
    fetchData();
  }, []);

  
  useEffect(() => {
    if (organization) {
      fetchCheckIdp();
    }
  }, [organization]);

  // API call function to get IDP inputs
  async function idinputs() {
    try {
      setOpen(true);
      const res = await GetIdpInputs();

      console.log("API Response:", res);
      setOpen(false);
      if (res.success) {
        // Navigate to the desired route if the response is successful
        // navigate(`/UserGroups/${organization}`);
      } else {
        console.log("API call failed");
      }

      return res;
    } catch (e) {
      console.log("Error fetching IDP Inputs:", e);
      return { success: false, message: "Error in GetIdpInputs" };
    }
  }


  const fetchCheckIdp = async () => {
    try {
      const checkidp = await CheckIdp(organization); // Call the API to check the IDP status
      if (checkidp.success) {
        // If the response is successful, navigate to the UserGroups page
        navigate(`/UserGroups/${organization}`);
      } else {
        // If the response is not successful, handle the error (stay on the page)
        console.error("Credentials not exist:", checkidp.message);
        // Optionally, you can show an alert or a message on the page
        alert(checkidp.message || "Failed to fetch IDP credentials.");
      }
    } catch (error) {
      console.error("Error fetching IDP credentials:", error);
      // Optionally, handle any network or server errors here
    }
  };

  // Handle dropdown change and dynamically show the component based on selected IDP
  const handleChange = (event) => {
    const selectedIdp = event.target.value;
    setIdp(selectedIdp);

    // Show component only if a valid IDP is selected
    setShowComponent(!!selectedIdp && idpData[selectedIdp]);

    // Update form data with selected IDP details if available, or reset formData
    if (idpData[selectedIdp]) {
      setFormData({ ...idpData[selectedIdp] });
    } else {
      setFormData({});
    }
  };

  // Handle input changes and update form data state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission to call PostStoreAd API
  const handleSubmit = async () => {
    try {
      setOpen(true);
      const response = await PostStoreAd(formData, organization);
      navigate(`/UserGroups/${organization}`);
      console.log("PostStoreAd Response:", response);
      // Show success message or handle response accordingly
    } catch (error) {
      console.error("Error in PostStoreAd:", error);
    }
    setOpen(false);
  };

  return (
    <div>
      <Charging open={open} />

      <div className={styles.heading}>
        <h5>USER MANAGEMENT - ADD AN IDP</h5>
      </div>

      <div style={{ margin: "20px" }}>
        {/* Dropdown to select IDP dynamically based on API data */}
        <select
          className={styles.dropdown}
          id="idp-select"
          value={idp}
          onChange={handleChange}
          disabled={!idpData} // Disable dropdown if data is not loaded
        >
          <option value="">Select IDP</option>
          {idpData &&
            idpData.drop_down.map((idpOption, index) => (
              <option key={index} value={idpOption}>
                {idpOption}
              </option>
            ))}
        </select>
      </div>

      {showComponent && idpData && idpData[idp] && (
        <>
          {/* Render fields dynamically based on the selected IDP using map */}
          <div className={styles.select}>
            <h5>{idp} Configuration</h5>
          </div>
          <div className={styles.ids}>
            {Object.keys(idpData[idp]).map((key, index) => (
              <div key={index} className={styles.inpContainer}>
                <label>{key.replace(/_/g, " ")}</label>
                <input
                  className={styles.inp}
                  name={key} // Set the name attribute to the key for dynamic updates
                  // value={formData[key] || ""} // Set value based on the formData state
                  // placeholder={idpData[idp][key]} // Display placeholder from API data
                  type={idpData[idp][key]} // Use "text" type for all inputs (can be customized)
                  onChange={handleInputChange} // Handle change for dynamic inputs
                />
              </div>
            ))}
          </div>
          <div className={styles.butto}>
            <button className={styles.cancel}>Cancel</button>
            <button className={styles.assign} onClick={handleSubmit}>
              Connect
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UsermanagementIdp;
