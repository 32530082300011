import React from "react";
import logo from "../assests/images/header/genaiLogo.png";
import Appcss from "../changepassword/Changepassword.module.css";
import frmcss from "../register/register.module.css";
import { useParams } from "react-router-dom";

const RegisterSuccess = () => {

  const {organization}=useParams();

    return (
        <div>
            <div className={Appcss.bdy}>
                <header>
                    <img className={Appcss.image} src={logo} alt="logo" />
                </header>
                <div className={Appcss.box}>
                    <h2 className={Appcss.reset}>Registration Successful</h2>
                    <p className={Appcss.txt}>
                        Check your inbox for activation link
                    </p>

                    <p className={frmcss.member}>
                        <a className={frmcss.register_now} href={`/TestingAide/${organization}`}>
                            Go back to Login
                        </a>
                    </p>

                </div>
            </div>
        </div>
    );
};

export default RegisterSuccess;
