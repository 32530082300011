import CommonTitleBar from "../CommonTitleBar";
import styles from "../user.module.css";
import plus from "../../assests/images/logo/plus.png";
import pencil from "../../assests/images/logo/pencil.png";
import deleteIcon from "../../assests/images/logo/deleteIcon.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Assignrole from "./AssignRole";

const RoleManagement = () => {
  const [open, setOpen] = useState(false);
  const [roleData, setroleData] = useState([]);
  const [rolesPermissions, setRolesPermissions] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { organization } = useParams();

  useEffect(() => {
    fetchRolesPermissions();
    fetchPermissions();
  }, []);

  function commonHeader() {
    let token = JSON.parse(localStorage.getItem("genAi"));
    let Header = { headers: { Authorization: `Bearer ${token}` } };
    return Header;
  }

  // Function to fetch roles permissions data
  const fetchRolesPermissions = async () => {
    try {
      const response = await axios.get(
        `http://54.85.255.13:9098/v1/roles_permissions/${organization}`,
        commonHeader()
      );

      if (response.data.success) {
        setRolesPermissions(response.data.roles_permissions);
        console.log(
          "Roles and Permissions Data:",
          response.data.roles_permissions
        );
      } else {
        console.error("Error: Failed to fetch roles and permissions data.");
      }
    } catch (error) {
      console.error("Error fetching roles permissions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetching permission keys from get_permissions API
  const fetchPermissions = async () => {
    try {
      const response = await axios.get(
        "http://54.85.255.13:9098/v1/get_permission/testaide",
        commonHeader()
      );

      if (response.data.success) {
        setPermissions(response.data.data);
        console.log("Permissions Data:", response.data.data);
      } else {
        console.error("Error: Failed to fetch permissions.");
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // Helper function to render permission names dynamically
  const getPermissionLabel = (permissionKey) => {
    if (permissions) {
      for (const category of Object.keys(permissions)) {
        if (permissions[category][permissionKey]) {
          return permissions[category][permissionKey];
        }
      }
    }
    return permissionKey; // Fallback in case permission name is not found
  };

  return (
    <div>
      <CommonTitleBar />
      <div>
        <div className={styles.inneruser}>
          <div className={styles.flex}>
            <Link to={`/roleManagement/rolecreate/${organization}`}>
              <div className={styles.commonbtn}>
                <img src={plus} alt="" /> <span>Create New Role</span>
              </div>
            </Link>
            <div className={styles.commonbtn}>
              <img src={pencil} alt="" /> <span>Edit</span>
            </div>
            <div className={styles.commonbtn}>
              <img src={deleteIcon} alt="" /> <span>Delete</span>
            </div>
          </div>
        </div>
        <div className={styles.usertable}>
          <table>
            <thead>
              <tr>
                <th className={styles.padleft40}>User Roles</th>
                <th>Policies</th>
                <th>Created By</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {rolesPermissions &&
                Object.keys(rolesPermissions).map((roleName, idx) => (
                  <tr key={idx}>
                    <td>
                      <div className={styles.d_table}>
                        <input
                          autoComplete="off"
                          type="checkbox"
                          value={roleName}
                          className={styles.checkboxforrole}
                        />
                        <label>{roleName}</label>
                      </div>
                    </td>

                    <td>
                      {/* Access the permissions object and iterate through it */}
                      {rolesPermissions[roleName].permissions &&
                        Object.keys(rolesPermissions[roleName].permissions).map(
                          (category, index) => (
                            <div className={styles.details} key={index}>
                              <h2>{category}</h2>
                              <ul>
                                {Object.entries(
                                  rolesPermissions[roleName].permissions[
                                    category
                                  ]
                                )
                                  .filter(
                                    ([permissionKey, permissionValue]) =>
                                      permissionValue // Show only true permissions
                                  )
                                  .map(([permissionKey]) => (
                                    <li key={permissionKey}>
                                      {getPermissionLabel(permissionKey)}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )
                        )}
                    </td>

                    <td>
                      <div className={styles.d_table}>Tarun</div>
                    </td>

                    <td>
                      <div className={styles.d_table}>N/A</div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;
