import React, { useState ,useEffect} from "react";
import CommonTitleBar from "../CommonTitleBar";
import styles from "../user.module.css";
import close from "../../assests/images/logo/close.png";
import plus from "../../assests/images/logo/plus.png";
import pencil from "../../assests/images/logo/pencil.png";
import deleteIcon from "../../assests/images/logo/deleteIcon.png";
import { Link, useParams } from "react-router-dom";
import { FormControl, MenuItem, Modal, Select } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { GetUserMangeUsers, StoreUserRoles } from "../../commonApi";
import Assignrole from "../rolemanagement/AssignRole";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const businessunit = [
  "business unit 1",
  "business unit 2",
  "business unit 3",
  "business unit 4",
  "business unit 5",
];

const UserManagement = () => {

  const [users,setUsers]=useState();
  const { organization } = useParams(); 
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null); 
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (organization) {
      fetchUsers();
    
    }
  }, [organization]);





  const fetchUsers = async () => {
    try {
      const usersData = await GetUserMangeUsers(organization); // API call
      if (usersData && usersData.success) {
        setUsers(usersData.users); // Set users from API response
      } else {
        console.error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleConfirmAssign = () => {
    console.log("Business unit assigned!"); // You can add your logic here
    setShowPopup(false); // Close popup after confirming
  };

  const handleOpenpopup=()=>{
    setShowPopup(true)
  }
  const handleUserSelection = (userId) => {
    setSelectedUserId(userId); // Set the selected user's ID when the checkbox is clicked
  };


  const handleAssignRole = () => {
    if (!selectedUserId) {
      alert("Please select a user.");
      return;
    }
    setShowPopup(true); // Show the Assignrole popup
  };

  const handleConfirm = async (selectedRole) => {
    if (!selectedRole || !selectedUserId) {
      alert("Please select a role and user.");
      return;
    }

    const data = {
      user_roles: [
        {
          role_id: selectedRole.id, // Pass the selected role's id
          user_id: selectedUserId, // Use the selected user's ID
        },
      ],
    };

    try {
      setLoading(true);

      // Send the request to store user roles
      const response = await StoreUserRoles(data, organization);

      if (response.success) {
        console.log("Assignment Successful:", response.data);

        alert("Role assigned successfully!");

        // Fetch updated users after role assignment
        fetchUsers();
        setShowPopup(false); // Close the modal after success
      } else {
        console.error("Assignment failed:", response.message);
        alert("Failed to assign role.");
      }
    } catch (error) {
      console.error("Error during assignment:", error);
      alert("An error occurred during assignment.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <CommonTitleBar />

      <div className={styles.inneruser}>
      <button  onClick={handleAssignRole} className={styles.commonbtn1}>Assign role</button>

        {/* <div className={styles.flex}>
          <Link to="/userManagement/usercreate">
            <div className={styles.commonbtn}>
              <img src={plus} alt="" /> <span>New User</span>
            </div>
          </Link>
          <div className={styles.commonbtn} style={{ opacity: 0.5 }}>
            <img src={pencil} alt="" /> <span> Edit</span>
          </div>
          <div className={styles.commonbtn} style={{ opacity: 0.5 }}>
            <img src={deleteIcon} alt="" /> <span>Delete</span>
          </div>
        </div> */}
      </div>
      <div className={styles.usertable}>
        <table>
          <thead>
            <tr>
              <th className={styles.padlef40}>
                <input type="checkbox" className={styles.usercheckbox} />
                <span className={styles.ml8}> FULL NAME</span>
              </th>
              <th>USER EMAIL</th>
              <th>BUSINESS UNIT</th>
              <th>USER ROLE</th>
              <th>CREATED AT</th>
              {/* <th>STATUS</th> */}
            </tr>
          </thead>
          <tbody>
          {users?.map((user) => (
              <tr key={user.user_id}>
                <td className={styles.hovername_d_flex}>
                  <input type="checkbox" className={styles.usercheckbox}  onChange={() => handleUserSelection(user.user_id)} />
                  <td>{user.first_name + " " + user.last_name}</td>
                </td>
                <td>{user.email}</td>
                <td>{user.business_unit_name}</td>
                <td>{user.role_name}</td> {/* Modify this based on your actual role data */}
                <td>2024-10-10</td> {/* Replace with actual "created at" data */}
                {/* <td className={styles.green1}>
                  {user.status === 1 ? "Active" : "Pending"}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal open={false} className="flex-center">
        <div className={styles.modalshowforgot}>
          <img src={close} className={styles.closex} alt="" />
          <div className={styles.modal_con}>
            <h4 className={styles.green_edit}>Edit User</h4>
            <form>
              <div className={styles.d_flex_gap20}>
                <div className={styles.each_for_box}>
                  <label htmlFor="" className={styles.labelforgot}>
                    First Name
                  </label>
                  <input
                    autoComplete="off"
                    required
                    type="text"
                    value="John"
                    className={styles.inputcommontextforgot}
                    name="user_firstname"
                  />
                </div>
                <div className={styles.each_for_box}>
                  <label htmlFor="" className={styles.labelforgot}>
                    Last Name
                  </label>
                  <input
                    autoComplete="off"
                    required
                    type="text"
                    value="Doe"
                    className={styles.inputcommontextforgot}
                    name="user_last_name"
                  />
                </div>
              </div>

              <div className={styles.d_flex_gap20}>
                <div className={styles.each_for_box}>
                  <label htmlFor="" className={styles.labelforgot}>
                    Email ID
                  </label>
                  <input
                    autoComplete="off"
                    required
                    type="email"
                    value="johndoe@example.com"
                    className={styles.inputcommontextforgot}
                    name="email"
                  />
                </div>
              </div>

              <div className={styles.d_flex_gap20}>
                <div className={styles.each_for_box}>
                  <label className={styles.labelforgot}>Business unit</label>
                  <FormControl>
                    <InputLabel id="demo-multiple-checkbox-label"></InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={["business unit 1"]}
                      input={<OutlinedInput label="" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      className={styles.business1}
                    >
                      {businessunit.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={true} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.each_for_box}>
                  <label className={styles.labelforgot}>Assign User Role</label>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value="Role 1"
                      className={styles.business1}
                      name="assign_role"
                      required
                    >
                      <MenuItem value="Role 1">Role 1</MenuItem>
                      <MenuItem value="Role 2">Role 2</MenuItem>
                      <MenuItem value="Role 3">Role 3</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className={styles.modaleditbtn}>
                <button className={styles.commonbtn} type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {showPopup && (
        <Assignrole
          show={showPopup}
          onClose={() => setShowPopup(false)}
          userId={selectedUserId} // Pass the selected user's ID to Assignrole
          onConfirm={handleConfirm} // Pass handleConfirm to receive selected role
        />
      )}
    </div>
  );
};

export default UserManagement;
