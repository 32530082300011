import React, { useEffect, useState } from "react";
import styles from "../businessmanagement/ReassignBu.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { StoreUserRoles } from "../../commonApi";

const Assignrole = ({ show, onClose, userId, onConfirm }) => {
    const { organization } = useParams();
    const [selectedUnitName, setSelectedUnitName] = useState("");
    const [selectedRole, setSelectedRole] = useState(null); // Store selected role's details
    const [loading, setLoading] = useState(false);
    const [rolesPermissions, setRolesPermissions] = useState(null);
  
    useEffect(() => {
      if (organization) {
        fetchRolesPermissions();
      }
    }, [organization]);
  
    const commonHeader = () => {
      let token = JSON.parse(localStorage.getItem("genAi"));
      let Header = { headers: { Authorization: `Bearer ${token}` } };
      return Header;
    };
  
    const fetchRolesPermissions = async () => {
      try {
        const response = await axios.get(
          `http://172.31.75.131:9098/v1/roles_permissions/${organization}`,
          commonHeader()
        );
  
        if (response.data.success) {
          setRolesPermissions(response.data.roles_permissions);
        } else {
          console.error("Error: Failed to fetch roles and permissions data.");
        }
      } catch (error) {
        console.error("Error fetching roles permissions:", error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleUserSelection = (event) => {
      const selectedUnitName = event.target.value;
      setSelectedUnitName(selectedUnitName);
  
      // Store selected role details
      const selectedRoleData = rolesPermissions[selectedUnitName];
      setSelectedRole(selectedRoleData); // selectedRoleData will contain both the role id and permissions
    };
  
    const handleConfirm = () => {
      if (!selectedRole || !userId) {
        alert("Please select a role and user.");
        return;
      }
  
      // Pass the selected role back to UserManagement through onConfirm
      onConfirm(selectedRole);
    };
  
    if (!show) {
      return null;
    }
  
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.fnt}>Assign Role</div>
          <select
            className={styles.dDown}
            value={selectedUnitName}
            onChange={handleUserSelection}
            required
          >
            <option value="" disabled>
              Select a Role
            </option>
            {rolesPermissions &&
              Object.keys(rolesPermissions).map((unitName, idx) => (
                <option key={idx} value={unitName}>
                  {unitName}
                </option>
              ))}
          </select>
          <div className={styles.align}>
            <button
              className={styles.button_s}
              onClick={handleConfirm}
              disabled={loading}
            >
              {loading ? "Assigning..." : "Confirm"}
            </button>
            <button className={styles.button_c} onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  
export default Assignrole;
